<template>
  <v-container>
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
      >
        <h2 class="text-center mb-7">Tell us a bit about you or your company.</h2>
        <v-form>
          <v-text-field
            label="Contact Name"
            :value="briefing[0].name"
            outlined
          />
          <v-text-field
            label="Contact Number"
            :value="briefing[0].phone"
            outlined
            :counter="10"
          />
          <v-text-field
            label="Email"
            :value="briefing[0].email"
            outlined
          />
          <v-text-field
            label="Company"
            :value="briefing[0].company"
            outlined
          />
          <v-btn
            to="options"
            color="primary"
            class="px-10"
            large
            @click.native="scrollToTop()"
          >
            Next
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { briefingData } from '@/data/briefingData'

export default ({
  data() {
    return {
      briefing: briefingData
    }
  }
})
</script>
